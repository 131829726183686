import React from "react"
import NewsletterForm from "./forms/newsletter"
import ReageerForm from "./forms/reageer"
import VraagbaakForm from "./forms/vraagbaak"

interface Props {
  params: string
}

const Form = (props: Props): JSX.Element => {
  switch (props.params) {
    case "newsletter":
      return <NewsletterForm />
    case "reageer":
      return <ReageerForm />
    case "vraagbaak":
      return <VraagbaakForm />
    default:
      return <span>Unsupported form: {props.params}</span>
  }
}

export default Form
