import React from "react"

const ACCEPTED_FILE_TYPES = [
  "audio/*",
  "image/*",
  "video/*",
  "application/msword", // doc
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // docx
  "application/vnd.ms-powerpoint", // ppt
  "application/vnd.openxmlformats-officedocument.presentationml.presentation", // pptx
  "application/vnd.ms-excel", // xls
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // xlsx
  "application/pdf",
  "application/zip",
  "text/plain",
]

const VraagbaakForm = (): JSX.Element => (
  <form name="Vraagbaak" action="/main/vraagbaak/verstuurd" data-netlify="true">
    <input type="hidden" name="form-name" value="Vraagbaak" />
    <input type="text" name="naam" id="naam" placeholder="Naam*" required />
    <input
      type="text"
      name="organisatie"
      id="organisatie"
      placeholder="Organisatie"
    />
    <input type="text" name="functie" id="functie" placeholder="Functie" />
    <input
      type="email"
      name="email"
      id="email"
      placeholder="E-mail*"
      required
    />
    <input type="tel" name="phone" id="phone" placeholder="Telefoonnummer" />
    <select name="thema" id="thema" required>
      <option value="">Thema*</option>
      <option value="Wetgeving (Bevi, Bevt, Bevi → Bkl, Bal)">
        Wetgeving (Bevi, Bevt, Bevi → Bkl, Bal)
      </option>
      <option value="BRZO → Seveso">BRZO → Seveso</option>
      <option value="Bouwbesluit → Bbl">Bouwbesluit → Bbl</option>
      <option value="Rekenmodules (Safeti, CAROLA, RMB-II)">
        Rekenmodules (Safeti, CAROLA, RMB-II)
      </option>
      <option value="EV in Ruimtelijke Ordening → Omgevingsveiligheid in Omgevingsplan">
        EV in Ruimtelijke Ordening → Omgevingsveiligheid in Omgevingsplan
      </option>
      <option value="EV in beleid → Omgevingsveiligheid in Omgevingsvisie">
        EV in beleid → Omgevingsveiligheid in Omgevingsvisie
      </option>
      <option value="Verantwoording Groepsrisico → Aandachtsgebieden/voorschriftengebieden">
        Verantwoording Groepsrisico → Aandachtsgebieden/voorschriftengebieden
      </option>
      <option value="RRGS/Risicokaart → REV">RRGS/Risicokaart → REV</option>
      <option value="Informatiehuis EV (populatieservice, signaleringskaart, database kwetsbare objecten)">
        Informatiehuis EV (populatieservice, signaleringskaart, database
        kwetsbare objecten)
      </option>
      <option value="Brandweer/Veiligheidsregio">
        Brandweer/Veiligheidsregio
      </option>
      <option value="Modernisering Omgevingsveiligheid/Omgevingswet">
        Modernisering Omgevingsveiligheid/Omgevingswet
      </option>
      <option value="IOV (Impuls) → Meerjarenprogramma versterking omgevingsveiligheid">
        IOV (Impuls) → Meerjarenprogramma versterking omgevingsveiligheid
      </option>
      <option value="Rekenprogramma Gevers">Rekenprogramma Gevers</option>
      <option value="Lithium ion batterijen (buurtbatterijen)">
        Lithium ion batterijen (buurtbatterijen)
      </option>
      <option value="Waterstof">Waterstof</option>
      <option value="Emplacementen">Emplacementen</option>
      <option value="Algemeen/overig">Algemeen/overig</option>
    </select>
    <textarea name="vraag" id="vraag" placeholder="Vraag*" required></textarea>
    <input
      type="file"
      name="bijlage"
      id="bijlage"
      accept={ACCEPTED_FILE_TYPES.join(", ")}
    />
    <input
      type="hidden"
      name="Let op"
      value="Eventuele bijlagen zijn toegevoegd door de verzender van het formulier en kunnen schadelijke bestanden bevatten."
    />
    <input type="submit" value="Stel vraag" />
  </form>
)

export default VraagbaakForm
