/** @jsx jsx */
import {jsx, css} from "@emotion/core"
import {useStaticQuery, graphql} from "gatsby"
import dayjs from "dayjs"
import {useMemo} from "react"

interface Props {
  params: string
  archived?: boolean
}

const KeywordLinks = (props: Props): JSX.Element => {
  const types = [
    "processKeywords",
    "regionKeywords",
    "themeKeywords",
    "documentType",
  ]
  const filters = props.params.split("+").join("").split(",")

  const data = useStaticQuery(graphql`
    query KeywordLinksQuery {
      pages: allDatoCmsDocument {
        edges {
          node {
            title
            slug
            author
            publicationDate
            archived
            processKeywords {
              key
            }
            regionKeywords {
              key
            }
            themeKeywords {
              key
            }
            documentType {
              key
            }
          }
        }
      }
    }
  `)

  const matches = useMemo(() => {
    const keywordPageMap = {}

    data.pages.edges.forEach(page => {
      if (page.node.archived != !!props.archived) return

      for (const type of types) {
        const keywords =
          type == "documentType" ? [page.node[type]] : page.node[type]

        keywords
          .filter(k => k)
          .forEach(keyword => {
            keywordPageMap[keyword.key.toLowerCase()] = (
              keywordPageMap[keyword.key.toLowerCase()] || []
            ).concat([page])
          })
      }
    })

    return filters
      .map(filter => keywordPageMap[filter.toLowerCase()] || [])
      .reduce((a, b) => a.filter(c => b.includes(c)))
  }, [])

  if (!matches.length && props.archived) {
    return <span>Er zijn geen documenten gevonden in dit archief.</span>
  }

  return (
    <ul className="document-link">
      {matches.map(page => (
        <li css={KeywordLinkStyles} key={page.node.slug}>
          <a href={`/documenten/${page.node.slug}`}>{page.node.title}</a>
          <div className="metadata">
            {dayjs(new Date(page.node.publicationDate)).format("YYYY")},{" "}
            {page.node.author}
          </div>
        </li>
      ))}
    </ul>
  )
}

export default KeywordLinks

const KeywordLinkStyles = css`
  .metadata {
    display: none;
    font-size: 1.7rem;
    font-style: italic;
  }
`
