import React from "react"
import "./createsend"

const NewsletterForm = (): JSX.Element => (
  <form
    className="js-cm-form"
    id="subForm"
    action="https://www.createsend.com/t/subscribeerror?description="
    method="post"
    data-id="5B5E7037DA78A748374AD499497E309E345B67356BD67A1804246BA4F3E8BE029F317D6BCC00A80A2D511165F5CB15C00243E00480085EC54EB218E03680499B"
  >
    <input
      autoComplete="Email"
      aria-label="E-mailadres"
      className="js-cm-email-input qa-input-email"
      id="fieldEmail"
      name="cm-pcutk-pcutk"
      placeholder="E-mailadres"
      required
      type="email"
    />
    <input type="submit" value="Aanmelden" />

    <h4>Overige informatie (optioneel):</h4>
    <input type="radio" name="cm-fo-hkddlt" id="cm814885" value="814885" />
    <label htmlFor="cm814885">Dhr.</label>
    <input type="radio" name="cm-fo-hkddlt" id="cm814886" value="814886" />
    <label htmlFor="cm814886">Mevr.</label>
    <input
      type="text"
      name="cm-f-hkdiuk"
      id="Firstname"
      placeholder="Voornaam"
    />
    <input type="text" name="cm-name" id="name" placeholder="Achternaam" />
    <input
      type="text"
      name="cm-f-hkddlr"
      id="Organisation"
      placeholder="Organisatie"
    />
    <input
      type="text"
      name="cm-f-hkddly"
      id="Department"
      placeholder="Afdeling"
    />
    <input type="text" name="cm-f-hkddlj" id="Position" placeholder="Functie" />
    <input type="submit" value="Aanmelden" />
  </form>
)

export default NewsletterForm
