import React from "react"

const ReageerForm = (): JSX.Element => (
  <form name="Reageer" action="/main/reageer/verstuurd" data-netlify="true">
    <input type="hidden" name="form-name" value="Reageer" />
    <input type="text" name="naam" id="naam" placeholder="Naam*" required />
    <input
      type="email"
      name="email"
      id="email"
      placeholder="E-mail*"
      required
    />
    <input
      type="text"
      name="organisatie"
      id="organisatie"
      placeholder="Organisatie"
    />
    <input type="text" name="functie" id="functie" placeholder="Functie" />
    <textarea
      name="feedback"
      id="feedback"
      placeholder="Uw feedback*"
      required
    ></textarea>
    <input type="submit" value="Versturen" />
  </form>
)

export default ReageerForm
