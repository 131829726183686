import React from "react"
import {useStaticQuery, graphql} from "gatsby"

import {Page} from "../../types"

interface Props {
  page: Page
  params: string
}

const ChildrenLinks = (props: Props): JSX.Element => {
  const data = useStaticQuery(graphql`
    query ChildrenLinksQuery {
      pages: allDatoCmsPage {
        edges {
          node {
            title
            slug
            parentPage {
              ... on DatoCmsPage {
                title
                slug
              }
            }
          }
        }
      }
    }
  `)

  const matches = () => {
    return data.pages.edges.filter(
      page => page.node.parentPage?.slug === props.page.slug,
    )
  }

  return (
    <ul>
      {matches().map(page => (
        <li key={page.node.slug}>
          <a href={`/${page.node.slug}`}>{page.node.title}</a>
        </li>
      ))}
    </ul>
  )
}

export default ChildrenLinks
