import React from "react"
import {DomElement} from "html-react-parser"

import Form from "../components/form"
import KeywordLinks from "../components/keyword-links"
import ChildrenLinks from "../components/children-links"

import {Page} from "../../types"

export const macroReplacer = (source: Page, object: DomElement): DomElement => {
  if (object.name == "p" && object.children && object.children.length == 1) {
    const child = object.children[0]
    if (child.type == "text") {
      const matches = child.data.match(/{{(\w+):?(.+)?}}/)
      if (matches) {
        const [_, name, params] = matches
        switch (name) {
          case "archive":
            return <KeywordLinks params={params} archived />
          case "children":
            return <ChildrenLinks page={source} params={params} />
          case "docs":
            return <KeywordLinks params={params} />
          case "form":
            return <Form params={params} />
        }
      }
    }
  }
  return object
}

export const divideTextInLines = (
  width: number,
  fontMap: Record<number, number>,
  text: string,
  maxLines?: number,
): string[] => {
  const wordWidths = []
  text.split(" ").forEach(word => {
    let length = 0
    word
      .split("")
      .forEach(letter => (length += fontMap[letter.charCodeAt(0)] || 8))
    wordWidths.push([word, length])
  })

  const lines = [...Array(maxLines || 10)].map((_, i) => {
    let totalLength = 0
    const words = []
    while (totalLength < width + 100) {
      if (wordWidths.length == 0) return words.join(" ")

      totalLength += wordWidths[0][1] + 8 // 8 = space
      if (totalLength < width || i == (maxLines || 10) - 1) {
        words.push(wordWidths[0][0])
        wordWidths.shift()
      }
    }

    return words.join(" ")
  })

  return lines.filter(line => line.length > 0)
}
